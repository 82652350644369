import { useEffect } from 'react';
import ReactGA4 from 'react-ga4';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { eventTypes, GA4_ID, trackEvent } from '../../utils/tracking';

const GA4Tracking = ({ apiKey }) => {
  useEffect(() => {
    ReactGA4.gtag('consent', 'default', {
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'granted',
    });

    ReactGA4.initialize(GA4_ID);
    trackEvent(eventTypes.initialLoad, apiKey);
  }, [apiKey]);

  const { pathname } = useLocation();

  useEffect(() => {
    trackEvent(eventTypes.pageView, apiKey, { page: pathname });
  }, [apiKey, pathname]);

  return null;
};

GA4Tracking.propTypes = {
  apiKey: PropTypes.string,
};

GA4Tracking.defaultProps = {
  apiKey: '',
};

export default GA4Tracking;
