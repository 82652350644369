import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { format, isDate } from 'date-fns';
import DateSelector from '../../components/DateSelector/DateSelector';
import FixtureGrid from '../../components/FixtureGrid/FixtureGrid';
import { usePaginatedRequest } from '../../utils/general';
import { themeProps } from '../../utils/customPropTypes';

const FixtureBrowser = ({
  apiKey,
  theme,
  buttonText,
  onFixtureClick,
  titleClasses,
  containerClasses,
  dateSelectorClasses,
  endpoint,
}) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState('home_allFixtures');

  const [data, loading, hasMorePages, fetchNextPage, loadingPage] = usePaginatedRequest(
    endpoint,
    8,
    {
      apiKey,
      localDate: !isDate(selectedDate) ? undefined : format(selectedDate, 'y-M-d'),
      localTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    [selectedDate],
  );
  const nothing = '';
  return (
    <div>
      <button
        className="m-3 rounded-lg bg-black px-2 py-1 text-white"
        type="button"
        onClick={() => nothing.click()}
      >
        Crash test button
      </button>
      <p className={`break-words pt-8 ${containerClasses} ${titleClasses}`}>
        {t('home_upcomingFixtures')}
      </p>
      <DateSelector
        selectedDate={selectedDate}
        onPressDate={d => setSelectedDate(d)}
        apiKey={apiKey}
        mainBrand={theme?.mainBrand}
        containerClasses={dateSelectorClasses}
      />
      <FixtureGrid
        apiKey={apiKey}
        loadMore={hasMorePages && fetchNextPage}
        loadingPage={loadingPage}
        loading={loading}
        fixtures={data}
        theme={theme}
        buttonText={buttonText}
        onFixtureClick={onFixtureClick}
        containerClasses={containerClasses}
      />
    </div>
  );
};

FixtureBrowser.propTypes = {
  apiKey: PropTypes.string.isRequired,
  theme: themeProps,
  buttonText: PropTypes.string.isRequired,
  onFixtureClick: PropTypes.func,
  titleClasses: PropTypes.string,
  containerClasses: PropTypes.string,
  dateSelectorClasses: PropTypes.string,
  endpoint: PropTypes.string,
};

FixtureBrowser.defaultProps = {
  theme: undefined,
  endpoint: 'fixtures',
  onFixtureClick: () => {},
  titleClasses: '',
  containerClasses: 'container mx-auto',
  dateSelectorClasses: 'px-6 sm:container sm:mx-auto sm:px-0',
};

export default FixtureBrowser;
