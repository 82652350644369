import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ onClick, className, children }) => (
  <button type="button" onClick={onClick} className={`outline-none ${className}`}>
    {children}
  </button>
);

Button.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
};

Button.defaultProps = {
  onClick: () => {},
  className: '',
  children: null,
};

export default Button;
