import { useState, useCallback, useEffect, useRef } from 'react';
import i18next from 'i18next';

export const CDNURL = 'https://cdn.internal.matchpint.cloud/';

export const getURLQuery = (parameters = {}) => {
  const BOOLEAN = 'boolean';
  const NUMBER = 'number';
  const OBJECT = 'object';
  const STRING = 'string';

  const params = [];
  const keys = Object.keys(parameters);
  keys.forEach(_key => {
    const key = [encodeURIComponent(_key)];
    if (parameters[key] === null || parameters[key] === '') {
      params.push(key);
    } else {
      switch (typeof parameters[key]) {
        case BOOLEAN:
          params.push(`${key}=${parameters[key] ? '1' : '0'}`);
          break;
        case NUMBER:
        case STRING:
          params.push(`${key}=${encodeURIComponent(parameters[key])}`);
          break;
        case OBJECT: {
          if (Array.isArray(parameters[key])) {
            params.push(parameters[key].map(param => `${key}[]=${param}`).join('&'));
          }
          break;
        }
        default:
          break;
      }
    }
  });

  return params.length === 0 ? '' : `?${params.join('&')}`;
};

export const getNavigationParams = (address, coordinate, name, googlePlaceId) => {
  const destinationAddress = address || '';

  let destinationParam;
  if (!destinationAddress && coordinate) {
    // fallback if address missing
    destinationParam = `${coordinate?.latitude},${coordinate?.longitude}`;
  } else {
    destinationParam = `${name}, ${destinationAddress}`;
  }
  return {
    api: 1,
    destination: destinationParam,
    destination_place_id: googlePlaceId,
  };
};

export const mapsAPIKey = 'AIzaSyDuS7NX5wwh7Q4nYZw9nB3ZvqyfGMlA4nY';

export const request = (endpoint, params) =>
  fetch(`${process.env.REACT_APP_DOMAIN}${endpoint}${getURLQuery(params)}`, {
    headers: { locale: i18next.resolvedLanguage },
  }).then(r => {
    if (r.ok) {
      return r.json();
    }
    throw r;
  });

export const usePaginatedRequest = (
  endpoint,
  limit = 10,
  params = {},
  paramsToRerender = [],
  condition = true,
) => {
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [hasMorePages, setHasMorePages] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);

  const checkIfMoreMatches = (tempmatches, templength, templimit, pageSize) => {
    if (tempmatches) {
      return tempmatches > templength;
    }
    return pageSize === templimit;
  };

  const fetch = useCallback(() => {
    if (condition) {
      setLoading(true);
      request(endpoint, {
        limit,
        page: 1,
        ...params,
      })
        .then(({ data: newData, meta: { total } }) => {
          setError(false);
          setHasMorePages(checkIfMoreMatches(total, newData.length, limit, newData.length));
          setOffset(0 + newData.length);
          setData(newData);
        })
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    }
  }, [condition, endpoint, params, limit]);

  const fetchNextPage = useCallback(() => {
    setLoadingPage(true);
    request(endpoint, {
      limit,
      page: offset / limit + 1,
      ...params,
    })
      .then(({ data: newData, meta: { total } }) => {
        setError(false);
        setHasMorePages(
          checkIfMoreMatches(total, [...newData, ...data].length, limit, newData.length),
        );
        setOffset(offset + newData.length);
        setData([...data, ...newData]);
      })
      .catch(() => setError(true))
      .finally(() => setLoadingPage(false));
  }, [data, endpoint, limit, offset, params]);

  useEffect(() => {
    // RE-FETCH IF DOMAIN CHANGES
    fetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, paramsToRerender);

  return [data, loading, hasMorePages, fetchNextPage, loadingPage, error];
};

export const debounce = (callback, time = 1000) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback.apply(this, args);
    }, time);
  };
};

export const useRequest = () => {
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);

  const clearResults = () => setResults(null);

  const tempRequest = useCallback((...args) => {
    setLoading(true);
    request(...args)
      .then(({ data }) => setResults(data))
      .finally(() => setLoading(false));
  }, []);

  return [loading, results, tempRequest, clearResults];
};

export const useScrollableVenue = (selectedVenueId, borderColor, onFinish = () => {}) => {
  const selectedVenueRef = useRef(null);

  useEffect(() => {
    if (selectedVenueId && selectedVenueRef?.current) {
      selectedVenueRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      const firstChildStyle = selectedVenueRef.current;
      firstChildStyle.style.transition = 'border-color 0.9s ease';
      firstChildStyle.style.borderColor = borderColor || 'black';

      setTimeout(() => {
        firstChildStyle.style.borderColor = null;
        onFinish();
      }, 4000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVenueId]);

  return selectedVenueRef;
};
