import PropTypes from 'prop-types';

export const sportProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  image: PropTypes.string,
  logo: PropTypes.string,
  name: PropTypes.string.isRequired,
});

export const competitionSummary = PropTypes.shape({
  id: PropTypes.number.isRequired,
  image: PropTypes.string,
  logo: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
});

export const fixtureTeam = PropTypes.shape({
  id: PropTypes.number.isRequired,
  image: PropTypes.string,
  logo: PropTypes.string,
  name: PropTypes.string.isRequired,
  countryId: PropTypes.number,
  alias: PropTypes.arrayOf(PropTypes.string),
});

export const schedule = PropTypes.shape({
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
});

export const fixtureSummary = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  sport: PropTypes.oneOfType([PropTypes.number.isRequired, sportProps.isRequired]),
  teams: PropTypes.shape({
    home: fixtureTeam,
    away: fixtureTeam,
  }),
  competition: competitionSummary,
  schedule,
});

export const fixturesProps = PropTypes.arrayOf(fixtureSummary);
const openingTimes = PropTypes.shape({
  close: PropTypes.string,
  open: PropTypes.string,
});

export const openingTimesProps = PropTypes.shape({
  friday: openingTimes,
  monday: openingTimes,
  saturday: openingTimes,
  sunday: openingTimes,
  thursday: openingTimes,
  tuesday: openingTimes,
  wednesday: openingTimes,
});

export const locationProps = PropTypes.shape({
  latitude: PropTypes.number,
  longitude: PropTypes.number,
});

export const facilitiesProps = PropTypes.shape({
  allowsPet: PropTypes.number,
  hasBigScreen: PropTypes.number,
  hasGarden: PropTypes.number,
  hasOutdoorScreen: PropTypes.number,
  hasWifi: PropTypes.number,
  numberOfScreens: PropTypes.number,
  servesFood: PropTypes.number,
});

export const venueProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  website: PropTypes.string,
  phoneNumber: PropTypes.string,
  menu: PropTypes.string,
  longDescription: PropTypes.string,
  profilePicture: PropTypes.shape().isRequired,
  images: PropTypes.arrayOf(PropTypes.shape()),
  facilities: facilitiesProps,
  location: PropTypes.shape({
    address1: PropTypes.string,
    coordinate: locationProps.isRequired,
    googlePlaceId: PropTypes.string,
  }),
  openingTimes: openingTimesProps,
  sports: PropTypes.arrayOf(PropTypes.shape()),
  teams: PropTypes.arrayOf(PropTypes.shape()),
});

export const themeProps = PropTypes.shape({
  id: PropTypes.number,
  api_id: PropTypes.number,
  title: PropTypes.string,
  sub_title: PropTypes.string,
  logo: PropTypes.string,
  powered_by_link: PropTypes.string,
});
