import React, { createRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './ImageCarousel.module.scss';
import Chevron from '../../Icons/Chevron';

const ImageCarousel = ({ urls }) => {
  const { t } = useTranslation();
  const [currentImage, setCurrentImage] = useState(0);

  const cleanedUrls = urls?.filter(el => Boolean(el));

  const refs = cleanedUrls?.reduce((acc, _, i) => {
    acc[i] = createRef();
    return acc;
  }, {});

  const scrollToImage = i => {
    setCurrentImage(i);
    refs[i].current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  };

  const totalImages = cleanedUrls?.length;

  const nextImage = () =>
    currentImage >= totalImages - 1 ? scrollToImage(0) : scrollToImage(currentImage + 1);

  const prevImage = () =>
    currentImage === 0 ? scrollToImage(totalImages - 1) : scrollToImage(currentImage - 1);

  const sliderControl = (onClick, Component, label) => (
    <button
      aria-label={t(label)}
      className="rounded-full bg-veryLightGrey p-2 hover:opacity-75"
      type="button"
      onClick={onClick}
    >
      {Component}
    </button>
  );

  return (
    cleanedUrls?.length > 0 && (
      <div className="lg:mx-16">
        <div className={styles.carousel}>
          {cleanedUrls?.map((img, i) => img && <img ref={refs[i]} src={img} alt="" key={img} />)}
          <div className="md:min-w-full" />
        </div>
        <div className="hiddenA items-center gap-2 md:pl-4 lg:pl-0 xl:flex">
          <p className="mr-2 text-xl font-bold text-[#949494]">
            {`${currentImage + 1}/${totalImages}`}
          </p>
          {sliderControl(
            prevImage,
            <Chevron className="-rotate-90" size={15} />,
            'slider_previous',
          )}
          {sliderControl(nextImage, <Chevron className="rotate-90" size={15} />, 'slider_next')}
        </div>
      </div>
    )
  );
};

ImageCarousel.propTypes = {
  urls: PropTypes.arrayOf(PropTypes.string),
};

ImageCarousel.defaultProps = {
  urls: [],
};

export default ImageCarousel;
