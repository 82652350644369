import React from 'react';

const Outdoor = () => (
  <svg width={32} height={32} viewBox="0 0 25 25">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-220.000000, -460.000000)" fill="#000000">
        <g transform="translate(0.000000, 375.000000)">
          <path d="M234,106 L234,110 L231,110 L231,106 L234,106 Z M239.571068,102.449747 L242.399495,105.278175 L240.278175,107.399495 L237.449747,104.571068 L239.571068,102.449747 Z M225.428932,102.449747 L227.550253,104.571068 L224.721825,107.399495 L222.600505,105.278175 L225.428932,102.449747 Z M232.5,91 C236.089851,91 239,93.9101491 239,97.5 C239,101.089851 236.089851,104 232.5,104 C228.910149,104 226,101.089851 226,97.5 C226,93.9101491 228.910149,91 232.5,91 Z M245,96 L245,99 L241,99 L241,96 L245,96 Z M224,96 L224,99 L220,99 L220,96 L224,96 Z M240.278175,87.6005051 L242.399495,89.7218254 L239.571068,92.5502525 L237.449747,90.4289322 L240.278175,87.6005051 Z M224.721825,87.6005051 L227.550253,90.4289322 L225.428932,92.5502525 L222.600505,89.7218254 L224.721825,87.6005051 Z M234,85 L234,89 L231,89 L231,85 L234,85 Z" />
        </g>
      </g>
    </g>
  </svg>
);

export default Outdoor;
