import React from 'react';

const Sound = () => (
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none">
    <g clipPath="url(#clip0_498_1604)">
      <path
        d="M21.2992 16.5525V9.35254C21.2992 6.69214 19.1572 4.52734 16.5244 4.52734C16.44 4.52785 16.3558 4.53792 16.2736 4.55734C15.0415 4.61708 13.8795 5.14817 13.0281 6.04073C12.1766 6.93328 11.7009 8.119 11.6992 9.35254V16.5525C11.6992 19.1997 13.852 21.3525 16.4992 21.3525C19.1464 21.3525 21.2992 19.1997 21.2992 16.5525ZM14.0992 16.5525V9.35254C14.0992 8.02894 15.1756 6.95254 16.4992 6.95254C16.5648 6.95256 16.6303 6.94654 16.6948 6.93454C17.9248 7.02454 18.8992 8.07454 18.8992 9.35254V16.5525C18.8992 17.8761 17.8228 18.9525 16.4992 18.9525C15.1756 18.9525 14.0992 17.8761 14.0992 16.5525Z"
        fill="black"
      />
      <path
        d="M9.29844 16.5527H6.89844C6.89844 21.4391 10.5716 25.4759 15.2984 26.0699V28.5527H17.6984V26.0699C22.4252 25.4759 26.0984 21.4403 26.0984 16.5527H23.6984C23.6984 20.5235 20.4692 23.7527 16.4984 23.7527C12.5276 23.7527 9.29844 20.5235 9.29844 16.5527Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_498_1604">
        <rect width="19.2" height="24" fill="white" transform="translate(6.89844 4.55273)" />
      </clipPath>
    </defs>
  </svg>
);

export default Sound;
