import React from 'react';

const ServesFood = () => (
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none">
    <path
      d="M8.99944 7.40988C8.99944 7.18255 8.90914 6.96453 8.74839 6.80379C8.58765 6.64304 8.36963 6.55273 8.1423 6.55273C7.91497 6.55273 7.69695 6.64304 7.53621 6.80379C7.37546 6.96453 7.28516 7.18255 7.28516 7.40988V12.5527C7.28495 13.5407 7.62611 14.4984 8.25089 15.2638C8.87567 16.0292 9.7457 16.5551 10.7137 16.7527V29.6956C10.7137 29.9229 10.804 30.1409 10.9648 30.3017C11.1255 30.4624 11.3435 30.5527 11.5709 30.5527C11.7982 30.5527 12.0162 30.4624 12.177 30.3017C12.3377 30.1409 12.428 29.9229 12.428 29.6956V16.7527C13.396 16.5551 14.2661 16.0292 14.8908 15.2638C15.5156 14.4984 15.8568 13.5407 15.8566 12.5527V7.40988C15.8566 7.18255 15.7663 6.96453 15.6055 6.80379C15.4448 6.64304 15.2268 6.55273 14.9994 6.55273C14.7721 6.55273 14.5541 6.64304 14.3934 6.80379C14.2326 6.96453 14.1423 7.18255 14.1423 7.40988V12.5527C14.1426 13.0847 13.9779 13.6037 13.6708 14.0381C13.3638 14.4726 12.9296 14.8011 12.428 14.9784V7.40988C12.428 7.18255 12.3377 6.96453 12.177 6.80379C12.0162 6.64304 11.7982 6.55273 11.5709 6.55273C11.3435 6.55273 11.1255 6.64304 10.9648 6.80379C10.804 6.96453 10.7137 7.18255 10.7137 7.40988V14.9784C10.2122 14.8011 9.77795 14.4726 9.47092 14.0381C9.16389 13.6037 8.99916 13.0847 8.99944 12.5527V7.40988ZM20.9994 29.6956V18.5527H18.428C18.2007 18.5527 17.9827 18.4624 17.8219 18.3017C17.6612 18.1409 17.5709 17.9229 17.5709 17.6956V10.8384C17.5709 9.70188 18.1297 8.63902 18.8926 7.87445C19.6572 7.11159 20.72 6.55273 21.8566 6.55273C22.0839 6.55273 22.3019 6.64304 22.4627 6.80379C22.6234 6.96453 22.7137 7.18255 22.7137 7.40988V29.6956C22.7137 29.9229 22.6234 30.1409 22.4627 30.3017C22.3019 30.4624 22.0839 30.5527 21.8566 30.5527C21.6293 30.5527 21.4112 30.4624 21.2505 30.3017C21.0897 30.1409 20.9994 29.9229 20.9994 29.6956Z"
      fill="black"
    />
  </svg>
);

export default ServesFood;
