import React from 'react';

const File = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      opacity="0.3"
      d="M4.8816 1.66602H11.3561C11.7108 1.66602 12.054 1.7917 12.3248 2.02076L16.136 5.2444C16.473 5.52941 16.6673 5.94834 16.6673 6.38966V16.7355C16.6673 18.2276 16.6503 18.3327 15.1197 18.3327H4.8816C3.35102 18.3327 3.33398 18.2276 3.33398 16.7355V3.26324C3.33398 1.77112 3.35102 1.66602 4.8816 1.66602Z"
      fill="black"
    />
    <rect x="5" y="9.16602" width="7.5" height="1.66667" rx="0.833333" fill="black" />
    <rect x="5" y="12.5" width="4.16667" height="1.66667" rx="0.833333" fill="black" />
  </svg>
);

export default File;
