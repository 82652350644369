import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header/Header';
import VenueList from '../../components/VenueList/VenueList';
import { useRequest } from '../../utils/general';
import TeamLogo from '../../components/TeamLogo/TeamLogo';
import { useLocaleDate } from '../../utils/date';
import Chevron from '../../components/Icons/Chevron';
import { trackEvent, eventTypes } from '../../utils/tracking';
import HeaderButton from '../../components/HeaderButton/HeaderButton';
import { themeProps } from '../../utils/customPropTypes';

const EntityIcon = ({ logo, name, className, leftPadding }) => (
  <div className={`${className} flex items-center`}>
    <TeamLogo image={logo} />
    <p
      className={`text-2xl font-bold lg:text-4xl ${leftPadding ? 'ml-4 lg:ml-8' : 'mx-4 lg:mx-8'}`}
    >
      {name}
    </p>
  </div>
);

EntityIcon.propTypes = {
  logo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  leftPadding: PropTypes.bool,
};

EntityIcon.defaultProps = {
  className: '',
  leftPadding: false,
};

const FixtureVenueList = ({ apiKey, theme }) => {
  const { fixtureId } = useParams();
  const [, fixture, request] = useRequest();
  const { goBack } = useHistory();
  const { t } = useTranslation();
  const getLocaleDate = useLocaleDate();

  useEffect(() => {
    request(`fixtures/${fixtureId}`, { apiKey });
  }, [apiKey, fixtureId, request]);

  const { name, competition, teams, schedule } = fixture || {};
  const isTeamFixture = Object.values(teams || {})?.length === 2;

  return (
    <>
      {fixture && (
        <Header backgroundImage={competition?.image} apiKey={apiKey}>
          <HeaderButton
            icon={<Chevron size={16} className="-rotate-90" />}
            text={t('fixtureVenueList_backText')}
            onClick={() => {
              trackEvent(eventTypes.back, apiKey);
              goBack();
            }}
          />
          <div className="mt-8 lg:text-center">
            <p className="text-xs uppercase opacity-90 lg:text-xl">
              {competition?.competitionOverride || competition?.name} |{' '}
              {getLocaleDate(schedule?.start, 'd MMMM | HH:mm')}
            </p>
            {!isTeamFixture ? (
              <EntityIcon
                name={name}
                logo={competition?.logo}
                leftPadding
                className="my-4 flex justify-center"
              />
            ) : (
              <div className="justify-center py-4 lg:flex">
                <EntityIcon name={teams?.home?.name} logo={teams?.home?.logo} />
                <EntityIcon
                  className="mt-4 lg:mt-0 lg:flex-row-reverse"
                  name={teams?.away?.name}
                  logo={teams?.away?.logo}
                />
              </div>
            )}
          </div>
        </Header>
      )}
      <VenueList apiKey={apiKey} theme={theme} additionalParams={{ showingFixture: fixtureId }} />
    </>
  );
};

FixtureVenueList.propTypes = {
  apiKey: PropTypes.string.isRequired,
  theme: themeProps.isRequired,
};

export default FixtureVenueList;
