import ReactGA4 from 'react-ga4';

export const GA4_ID = 'G-L09FSPZDW0';

export const eventTypes = {
  initialLoad: 'VenueFinderInitialLoad',
  pageView: 'VenueFinderPageView',
  viewFixture: 'pbf_view_fixture',
  venueListSearch: 'pbf_venue_list_search',
  seeMoreVenues: 'pbf_see_more_venues',
  seeMoreFixtures: 'pbf_see_more_fixtures',
  datePicker: 'pbf_date_picker',
  back: 'pbf_back',
  mapView: 'pbf_map_view',
  listView: 'pbf_list_view',
  viewVenue: 'pbf_view_venue',
  viewVenueMap: 'pbf_view_venue_map',
  website: 'pbf_website',
  phone: 'pbf_phone',
  directions: 'pbf_directions',
  bookTable: 'book_table',
};

export const trackEvent = (eventName, apiKey, params = {}) => {
  if (!eventName || !apiKey) {
    throw new Error('No API key or event name passed');
  }

  ReactGA4.event(eventName, { apiKey, ...params });
};
