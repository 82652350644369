import React from 'react';
import PropTypes from 'prop-types';

const TeamLogo = ({ image, size, className }) => (
  <div className={`rounded-xl bg-veryLightGrey ${className}`}>
    <img src={image} width={size} height={size} alt="" />
  </div>
);

TeamLogo.propTypes = {
  image: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.number,
};

TeamLogo.defaultProps = {
  className: 'p-3 lg:rounded-2xl',
  size: 30,
};

export default TeamLogo;
