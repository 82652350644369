import React from 'react';

const Projector = () => (
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none">
    <path
      d="M25.5 15.8027C25.5 16.0982 25.4418 16.3908 25.3287 16.6638C25.2157 16.9368 25.0499 17.1848 24.841 17.3937C24.6321 17.6027 24.384 17.7684 24.111 17.8815C23.8381 17.9945 23.5455 18.0527 23.25 18.0527C22.9545 18.0527 22.6619 17.9945 22.389 17.8815C22.116 17.7684 21.8679 17.6027 21.659 17.3937C21.4501 17.1848 21.2843 16.9368 21.1713 16.6638C21.0582 16.3908 21 16.0982 21 15.8027C21 15.206 21.2371 14.6337 21.659 14.2117C22.081 13.7898 22.6533 13.5527 23.25 13.5527C23.8467 13.5527 24.419 13.7898 24.841 14.2117C25.2629 14.6337 25.5 15.206 25.5 15.8027V15.8027ZM8.25 13.5527C8.05109 13.5527 7.86032 13.6318 7.71967 13.7724C7.57902 13.9131 7.5 14.1038 7.5 14.3027C7.5 14.5016 7.57902 14.6924 7.71967 14.8331C7.86032 14.9737 8.05109 15.0527 8.25 15.0527H14.25C14.4489 15.0527 14.6397 14.9737 14.7803 14.8331C14.921 14.6924 15 14.5016 15 14.3027C15 14.1038 14.921 13.9131 14.7803 13.7724C14.6397 13.6318 14.4489 13.5527 14.25 13.5527H8.25ZM8.25 16.5527C8.05109 16.5527 7.86032 16.6318 7.71967 16.7724C7.57902 16.9131 7.5 17.1038 7.5 17.3027C7.5 17.5016 7.57902 17.6924 7.71967 17.8331C7.86032 17.9737 8.05109 18.0527 8.25 18.0527H14.25C14.4489 18.0527 14.6397 17.9737 14.7803 17.8331C14.921 17.6924 15 17.5016 15 17.3027C15 17.1038 14.921 16.9131 14.7803 16.7724C14.6397 16.6318 14.4489 16.5527 14.25 16.5527H8.25Z"
      fill="black"
    />
    <path
      d="M4.5 13.5527C4.5 12.7571 4.81607 11.994 5.37868 11.4314C5.94129 10.8688 6.70435 10.5527 7.5 10.5527H25.5C26.2956 10.5527 27.0587 10.8688 27.6213 11.4314C28.1839 11.994 28.5 12.7571 28.5 13.5527V18.0527C28.5 18.8484 28.1839 19.6114 27.6213 20.1741C27.0587 20.7367 26.2956 21.0527 25.5 21.0527C25.5 21.4506 25.342 21.8321 25.0607 22.1134C24.7794 22.3947 24.3978 22.5527 24 22.5527H22.5C22.1022 22.5527 21.7206 22.3947 21.4393 22.1134C21.158 21.8321 21 21.4506 21 21.0527H12C12 21.4506 11.842 21.8321 11.5607 22.1134C11.2794 22.3947 10.8978 22.5527 10.5 22.5527H9C8.60218 22.5527 8.22064 22.3947 7.93934 22.1134C7.65804 21.8321 7.5 21.4506 7.5 21.0527C6.70435 21.0527 5.94129 20.7367 5.37868 20.1741C4.81607 19.6114 4.5 18.8484 4.5 18.0527V13.5527ZM7.5 12.0527C7.10218 12.0527 6.72064 12.2108 6.43934 12.4921C6.15804 12.7734 6 13.1549 6 13.5527V18.0527C6 18.4506 6.15804 18.8321 6.43934 19.1134C6.72064 19.3947 7.10218 19.5527 7.5 19.5527H25.5C25.8978 19.5527 26.2794 19.3947 26.5607 19.1134C26.842 18.8321 27 18.4506 27 18.0527V13.5527C27 13.1549 26.842 12.7734 26.5607 12.4921C26.2794 12.2108 25.8978 12.0527 25.5 12.0527H7.5Z"
      fill="black"
    />
  </svg>
);

export default Projector;
