import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Skeleton = ({ height, width, stylesString }) => (
  <div style={{ height, width }} className={`skeleton ${stylesString} `} />
);

Skeleton.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  stylesString: PropTypes.string,
};

Skeleton.defaultProps = {
  height: '',
  width: '',
  stylesString: '',
};

export default Skeleton;
