import React from 'react';
import PropTypes from 'prop-types';

const Close = ({ className }) => (
  <svg width="36" height="36" viewBox="0 0 36 36" className={`fill-current ${className}`}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.9914 11.4546L10.4744 26.2917L8.54102 24.009L26.058 9.17194L27.9914 11.4546Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.2026 26.2917L10.6354 11.4546L13.3303 9.17195L25.8975 24.009L23.2026 26.2917Z"
    />
  </svg>
);

Close.propTypes = {
  className: PropTypes.string,
};

Close.defaultProps = {
  className: 'h-9 w-9',
};

export default Close;
