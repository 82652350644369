import React from 'react';
import PropTypes from 'prop-types';

const Guests = ({ className }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" className={`fill-current ${className}`}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.13729 8.1415C9.49073 5.90996 11.6088 4.10095 13.8682 4.10095C16.1275 4.10095 17.6725 5.90997 17.3191 8.1415L17.1484 9.21897C16.795 11.4505 14.6769 13.2595 12.4176 13.2595H11.8721C9.91403 13.2595 8.57499 11.6917 8.8813 9.75771L9.13729 8.1415ZM13.6122 5.71717C12.2566 5.71717 10.9857 6.80258 10.7737 8.1415L10.5177 9.75771C10.3527 10.7991 11.0737 11.6433 12.1281 11.6433H12.6736C14.0292 11.6433 15.3 10.5579 15.5121 9.21897L15.6827 8.1415C15.8948 6.80258 14.9678 5.71717 13.6122 5.71717Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0082 14.4646H14.3391C15.0769 14.4646 15.672 14.4646 16.1512 14.4972C16.644 14.5308 17.0732 14.6015 17.4632 14.7722C18.4006 15.1822 19.0724 15.9688 19.3308 16.9589C19.4383 17.3708 19.4411 17.8058 19.3971 18.2977C19.3544 18.7761 19.2613 19.3639 19.1459 20.0926L19.1413 20.1213H17.505C17.626 19.3572 17.7099 18.8246 17.7471 18.4077C17.7837 17.9981 17.7686 17.7595 17.7211 17.5774C17.566 16.9834 17.1629 16.5114 16.6005 16.2653C16.428 16.1899 16.1948 16.1376 15.7844 16.1097C15.3669 16.0812 14.8277 16.0808 14.054 16.0808H10.7813C10.0077 16.0808 9.46832 16.0812 9.04172 16.1097C8.62255 16.1376 8.37274 16.1899 8.17639 16.2653C7.53598 16.5114 6.9834 16.9834 6.64019 17.5774C6.53496 17.7595 6.44426 17.9981 6.35108 18.4077C6.25625 18.8246 6.17145 19.3572 6.05043 20.1213H4.41406L4.41861 20.0927C4.53402 19.3639 4.62712 18.7761 4.73594 18.2977C4.84784 17.8058 4.98837 17.3708 5.22635 16.9589C5.79836 15.9688 6.71934 15.1822 7.78668 14.7722C8.23073 14.6015 8.68235 14.5308 9.18572 14.4972C9.67526 14.4646 10.2704 14.4646 11.0082 14.4646Z"
    />
  </svg>
);

Guests.propTypes = {
  className: PropTypes.string,
};

Guests.defaultProps = {
  className: 'h-6 w-6 md:h-8 md:w-8',
};

export default Guests;
