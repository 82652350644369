import React from 'react';
import PropTypes from 'prop-types';
import SimpleSpinner from '../SimpleSpinner/SimpleSpinner';

const SpinnerButton = ({ text, buttonStyle, loading, onClick }) => (
  <button type="button" disabled={loading} onClick={onClick} className={`p-2 ${buttonStyle}`}>
    <div className="flex items-center justify-center">
      <p style={{ opacity: loading ? 0 : 1 }}>{text}</p>
      <SimpleSpinner className={`${!loading ? 'hiddenA' : ''} absolute`} />
    </div>
  </button>
);

SpinnerButton.propTypes = {
  text: PropTypes.string.isRequired,
  buttonStyle: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};
SpinnerButton.defaultProps = {
  buttonStyle: '',
  loading: false,
  onClick: () => {},
};

export default SpinnerButton;
