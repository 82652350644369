import React from 'react';
import PropTypes from 'prop-types';
import Footer from '../Footer/Footer';
import { themeProps } from '../../utils/customPropTypes';

const Layout = ({ children, isJsWidget, theme }) => (
  <>
    {isJsWidget && (
      <link
        href="https://venuefinder.{{env}}fanzo.com/v2/css/index.{{version-hash}}.css"
        rel="stylesheet"
      />
    )}
    <div
      className={`${!isJsWidget ? 'min-h-screen ' : ''}flex relative flex-col bg-white text-left`}
    >
      {children}
      <Footer theme={theme} />
    </div>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isJsWidget: PropTypes.bool.isRequired,
  theme: themeProps,
};

Layout.defaultProps = {
  theme: undefined,
};

export default Layout;
