import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Mobile from '../../components/Icons/Mobile';
import Close from '../../components/Icons/Close';
import Button from '../../components/Button/Button';
import Guests from '../../components/Icons/Guests';

const styles = {
  phoneBook:
    'flex items-center justify-center w-full hover:opacity-90 bg-veryLightGrey text-black rounded-lg px-4 py-2',
  bookModal:
    'fixed sm:absolute bottom-0 right-0 z-[9] bg-[#1E1E1E] text-white w-screen p-4 rounded-t-2xl sm:rounded-2xl sm:bottom-auto sm:w-auto sm:top-4 sm:right-4',
};

const BookTableModal = ({ showBookModal, onModalChange, phoneNumber }) => {
  const { t } = useTranslation();
  return (
    showBookModal && (
      <div className={styles.bookModal}>
        <Button className="ml-auto flex rounded-full bg-[#2E2E2E] p-2" onClick={onModalChange}>
          <Close />
        </Button>
        <div className="flex flex-col items-center justify-center px-4 pb-4 pt-2 lg:px-12 lg:pb-12 lg:pt-4">
          <Guests className="h-20 w-20 lg:h-28 lg:w-28" />
          <h1 className="headingFont_lineHeight py-4 text-5xl lg:text-6xl">
            {t('venue_bookTable')}
          </h1>
          <p className="pb-8 text-lg opacity-60">{t('venue_contactToBook')}</p>
          <a className={styles.phoneBook} href={`tel:${phoneNumber}`}>
            <Mobile className="h-8 w-8" />
            <p className="ml-2 text-xl font-semibold">{phoneNumber}</p>
          </a>
        </div>
      </div>
    )
  );
};

BookTableModal.propTypes = {
  showBookModal: PropTypes.bool,
  onModalChange: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string,
};

BookTableModal.defaultProps = {
  showBookModal: false,
  phoneNumber: '',
};

export default BookTableModal;
