import React, { useEffect, useRef, useState } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { mapsAPIKey, useRequest } from '../../utils/general';
import Header from '../../components/Header/Header';
import ContactLinks from '../../components/VenueDetail/ContactLinks/ContactLinks';
import ImageCarousel from '../../components/VenueDetail/ImageCarousel/ImageCarousel';
import OpeningTimes from '../../components/VenueDetail/OpeningTimes/OpeningTimes';
import VenueFeatures from '../../components/VenueDetail/VenueFeatures/VenueFeatures';
import TeamLogo from '../../components/TeamLogo/TeamLogo';
import File from '../../components/Icons/File';
import Guests from '../../components/Icons/Guests';
import Close from '../../components/Icons/Close';
import Button from '../../components/Button/Button';
import BookTableModal from './BookTableModal';
import { eventTypes, trackEvent } from '../../utils/tracking';
import SimpleSpinner from '../../components/SimpleSpinner/SimpleSpinner';
import FixtureBrowser from './FixtureBrowser';
import { themeProps } from '../../utils/customPropTypes';
import HeaderButton from '../../components/HeaderButton/HeaderButton';

const styles = {
  section:
    'bg-white lg:w-2/3 rounded-lg border border-veryLightGrey pt-8 px-4 pb-4 lg:p-8 mb-4 lg:mb-8 lg:mx-16',
  menu: 'inline-flex items-center h-16 px-5 rounded-lg hover90 bg-veryLightGrey',
  bookBtn:
    'flex items-center bg-veryLightGrey hover:opacity-75 rounded-lg px-4 py-2 lg:px-10 min-w-[140px]',
};

const VenueDetail = ({ apiKey, theme, propVenueId, onClose }) => {
  const { t } = useTranslation();
  const params = useParams();
  const venueId = params.venueId || propVenueId;
  const [loading, venue, fetchVenue] = useRequest();
  const {
    profilePicture,
    images,
    longDescription,
    openingTimes,
    facilities,
    menu,
    teams,
    sports,
    location,
    phoneNumber,
    booking,
  } = venue || {};
  const canBook = booking?.platform !== 'none' && phoneNumber;

  const [showBookModal, setShowBookModal] = useState(false);
  const onModalChange = () => setShowBookModal(!showBookModal);
  const ref = useRef();

  useEffect(() => {
    fetchVenue(`venues/${venueId}/`, { apiKey });
  }, [apiKey, fetchVenue, venueId]);

  if (loading) {
    return (
      <div className="flex h-32 items-center justify-center bg-black opacity-30">
        <SimpleSpinner />
      </div>
    );
  }

  const sectionTitle = title => (
    <h2 className="headingFont_lineHeight pb-4 text-4xl md:text-left md:text-5xl lg:pb-6">
      {t(title)}
    </h2>
  );

  return (
    venue && (
      <div className="flex flex-col bg-[#fdfcfc]">
        <Header
          backgroundImage={profilePicture?.original}
          backText={t('general_venues')}
          apiKey={apiKey}
        >
          <div className="flex justify-end">
            <HeaderButton icon={<Close size={16} />} text={t('general.close')} onClick={onClose} />
          </div>
          <h1 className="headingFont_lineHeight mt-20 text-4xl lg:text-6xl">{venue.name}</h1>
        </Header>
        <div ref={ref} className="relative">
          <div
            className={`no-scrollbar mb-4 mt-4 flex justify-start gap-2 overflow-x-auto px-4 sm:flex-row-reverse sm:justify-end lg:mb-10 lg:mt-2 lg:px-16 ${
              canBook ? 'lg:justify-between' : ''
            }`}
          >
            {canBook && (
              <Button
                className={styles.bookBtn}
                onClick={() => {
                  onModalChange();
                  trackEvent(eventTypes.bookTable, apiKey, { venueID: venueId });
                }}
              >
                <Guests />
                <p className="ml-2 text-xs font-bold">{t('venue_bookTable')}</p>
              </Button>
            )}
            <ContactLinks venue={venue} apiKey={apiKey} />
            <BookTableModal
              showBookModal={showBookModal}
              onModalChange={onModalChange}
              phoneNumber={phoneNumber}
            />
          </div>
        </div>
        <div className="px-4 lg:px-0">
          <ImageCarousel
            urls={[
              profilePicture?.original,
              ...Object.values(images || {}).map(img => img?.original),
            ]}
          />

          {longDescription && (
            <div className="border-veryLightGrey pb-4 lg:mx-16 lg:pb-8 lg:pt-10 xl:mt-10 xl:border-t">
              {sectionTitle('venue_about')}
              <p className="text-darkGrey">{longDescription}</p>
            </div>
          )}
          <FixtureBrowser
            apiKey={apiKey}
            theme={theme}
            endpoint={`venues/${venue.id}/fixtures`}
            buttonText={t('venue_bookTableFixture')}
            onFixtureClick={
              canBook
                ? id => {
                    trackEvent(eventTypes.bookTable, apiKey, { venueID: venueId, eventID: id });
                    onModalChange();
                    if (window.screen.width > 639) {
                      ref?.current?.scrollIntoView({ behavior: 'smooth' });
                    }
                  }
                : null
            }
            titleClasses="headingFont_lineHeight text-4xl md:text-5xl"
            containerClasses="lg:mx-16"
            dateSelectorClasses="lg:px-16"
          />

          {openingTimes && (
            <div className={`${styles.section} mt-4`}>
              {sectionTitle('venue_hours')}
              <div className="flex flex-col lg:h-28 lg:flex-wrap">
                <OpeningTimes openingTimes={openingTimes} />
              </div>
            </div>
          )}

          <div className={styles.section}>
            {sectionTitle('venue_features')}
            <div className="flex flex-col lg:mt-4">
              <VenueFeatures facilities={facilities} />
            </div>
          </div>

          {menu && (
            <div className={styles.section}>
              {sectionTitle('venue_menu')}
              <div className="flex">
                <a href={menu} className={styles.menu} download target="_blank" rel="noreferrer">
                  <File />
                  <span className="mx-6 font-semibold">{t('venue_menuClick')}</span>
                </a>
              </div>
            </div>
          )}

          {teams?.length > 0 && (
            <div className={styles.section}>
              {sectionTitle('venue_favTeams')}
              <div className="flex w-full flex-wrap">
                {teams.map(({ name, logo, id }) => (
                  <div className="my-4 flex w-24 flex-col items-center" key={id}>
                    <TeamLogo image={logo} />
                    <p className="pt-2 text-center text-xs font-semibold text-darkGrey">{name}</p>
                  </div>
                ))}
              </div>
            </div>
          )}

          {sports?.length > 0 && (
            <div className={styles.section}>
              {sectionTitle('venue_favSports')}
              <div className="flex w-full flex-wrap gap-3">
                {sports.map(({ id, name }) => (
                  <div className="flex flex-col items-center" key={id}>
                    {name && (
                      <div className="flex items-center justify-center rounded-full bg-veryLightGrey px-3 py-2">
                        <p className="font-semibold text-grey">{name}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="h-[35vh] overflow-hidden rounded-md pb-4 lg:h-[50vh] lg:rounded-none lg:pb-0">
            <iframe
              style={{ border: 0, width: '100%', height: '100%' }}
              loading="lazy"
              title="map"
              allowFullScreen
              src={`https://www.google.com/maps/embed/v1/place?zoom=15&language=${
                i18next.resolvedLanguage
              }&q=${
                location?.googlePlaceId
                  ? `place_id:${location?.googlePlaceId}`
                  : `${location?.coordinate?.latitude},${location?.coordinate?.longitude}`
              }&key=${mapsAPIKey}`}
            />
          </div>
        </div>
      </div>
    )
  );
};

VenueDetail.propTypes = {
  apiKey: PropTypes.string.isRequired,
  theme: themeProps,
  propVenueId: PropTypes.number,
  onClose: PropTypes.func,
};

VenueDetail.defaultProps = {
  theme: undefined,
  propVenueId: null,
  onClose: null,
};

export default VenueDetail;
