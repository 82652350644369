import React from 'react';

const Screen = () => (
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none">
    <path
      d="M2.2 23.5535H12.2V27.1535H8.2V28.7535H17.8V27.1535H13.8V23.5535H23.8C24.1181 23.5531 24.4232 23.4266 24.6481 23.2016C24.8731 22.9767 24.9996 22.6717 25 22.3535V7.55352C24.9996 7.23537 24.8731 6.93036 24.6481 6.7054C24.4232 6.48043 24.1181 6.35389 23.8 6.35352H2.2C1.88185 6.35389 1.57684 6.48043 1.35188 6.7054C1.12692 6.93036 1.00037 7.23537 1 7.55352V22.3535C1.00037 22.6717 1.12692 22.9767 1.35188 23.2016C1.57684 23.4266 1.88185 23.5531 2.2 23.5535ZM2.6 7.95352H23.4V21.9535H2.6V7.95352Z"
      fill="black"
    />
  </svg>
);

export default Screen;
