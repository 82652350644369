import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Swiper from 'swiper';
// eslint-disable-next-line import/no-unresolved
import { Navigation, Pagination } from 'swiper/modules';
import './SwiperBundle.scss';
import './ImageSlider.scss';
import Chevron from '../../Icons/Chevron';

const ImageSlider = ({ images, onClick }) => {
  const swiperContainerRef = useRef(null);
  const swiperInstanceRef = useRef(null);
  const hasImages = images?.length > 0;

  useEffect(() => {
    if (hasImages) {
      swiperInstanceRef.current = new Swiper(swiperContainerRef.current, {
        modules: [Navigation, Pagination],
        pagination: {
          el: '.swiper-pagination',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    }

    return () => {
      if (swiperInstanceRef.current) {
        swiperInstanceRef.current.destroy(true, true);
      }
    };
  }, [hasImages]);

  if (!hasImages) {
    return null;
  }

  return (
    <div className="swiper-container" ref={swiperContainerRef}>
      <div className="swiper-wrapper">
        {images.map(image => (
          <button className="swiper-slide" key={image} type="button" onClick={onClick}>
            <div className="absolute h-full w-full bg-black opacity-20" />
            <img alt="" src={image} className="swipe-image" loading="lazy" />
            <div className="swiper-lazy-preloader swiper-lazy-preloader-white animate-spin" />
          </button>
        ))}
      </div>
      <div className="swiper-pagination" />
      <div className="swiper-button-next">
        <Chevron className="rotate-90 scale-50" />
      </div>
      <div className="swiper-button-prev">
        <Chevron className="-rotate-90 scale-50" />
      </div>
    </div>
  );
};

ImageSlider.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func.isRequired,
};

ImageSlider.defaultProps = {
  images: null,
};

export default ImageSlider;
