import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PageHeader from '../../components/PageHeader/PageHeader';
import { themeProps } from '../../utils/customPropTypes';
import FixtureBrowser from '../VenueDetail/FixtureBrowser';
import { eventTypes, trackEvent } from '../../utils/tracking';

const Home = ({ apiKey, theme }) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <>
      <PageHeader theme={theme} />
      <FixtureBrowser
        apiKey={apiKey}
        theme={theme}
        buttonText={t('fixtureList_findBars')}
        onFixtureClick={id => {
          trackEvent(eventTypes.viewFixture, apiKey, { eventID: id });
          history.push(`/venues/${id}?apiKey=${apiKey}`);
        }}
        titleClasses="text-center lg:text-left lg:text-3xl text-xl font-bold"
      />
    </>
  );
};

Home.propTypes = {
  apiKey: PropTypes.string.isRequired,
  theme: themeProps,
};

Home.defaultProps = {
  theme: undefined,
};

export default Home;
