import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import './index.scss';
import './i18n';
import Root from './pages/root';

const rootElement = document.getElementById('fanzo-venue-widget');
const jsAPIKey = rootElement.getAttribute('data-apiKey');
const jsInitialRoute = rootElement.getAttribute('data-initialRoute');

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

const bugSnagAPIKey = 'a94ae1f5f542a6ff56eec6b4c35dc3d1';

const isProduction = process.env.REACT_APP_IS_PROD === 'true';

if (isProduction) {
  Bugsnag.start({
    apiKey: bugSnagAPIKey,
    plugins: [new BugsnagPluginReact()],
  });
}

const ErrorBoundary = isProduction
  ? Bugsnag.getPlugin('react').createErrorBoundary(React)
  : React.Fragment;

ReactDOM.render(
  <ErrorBoundary>
    <Root
      isJSWidget={Boolean(jsAPIKey)}
      jsInitialRoute={jsInitialRoute}
      apiKey={params.apiKey || jsAPIKey}
    />
  </ErrorBoundary>,
  rootElement,
);
