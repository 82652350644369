import React from 'react';

const Wifi = () => (
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.6066 19.4379C20.1124 18.1378 18.1609 17.3508 16.0233 17.3508C13.8856 17.3508 11.9341 18.1378 10.4372 19.4405C10.3959 19.4778 10.3706 19.5296 10.3666 19.5852C10.3627 19.6407 10.3803 19.6956 10.416 19.7383L11.3731 20.8789C11.4502 20.9693 11.5858 20.98 11.6762 20.9029C12.8354 19.8819 14.3562 19.2651 16.0233 19.2651C17.6903 19.2651 19.2111 19.8819 20.3703 20.9002C20.4607 20.9773 20.5963 20.9667 20.6734 20.8763L21.6305 19.7357C21.705 19.6479 21.6943 19.515 21.6066 19.4379ZM24.728 15.713C22.3697 13.7482 19.336 12.565 16.0233 12.565C12.7105 12.565 9.67683 13.7482 7.31587 15.713C7.27268 15.7494 7.24564 15.8014 7.24066 15.8577C7.23568 15.914 7.25316 15.97 7.28928 16.0134L8.24643 17.154C8.32087 17.2444 8.45647 17.2551 8.54421 17.1806C10.5728 15.495 13.1811 14.4793 16.0233 14.4793C18.8654 14.4793 21.4737 15.495 23.4996 17.1806C23.59 17.2551 23.723 17.2444 23.7974 17.154L24.7546 16.0134C24.829 15.923 24.8184 15.7874 24.728 15.713ZM27.8307 12.0173C24.603 9.36923 20.4713 7.7793 15.9701 7.7793C11.4981 7.7793 7.39297 9.34796 4.17323 11.9642C4.15111 11.9819 4.13277 12.0039 4.11929 12.0289C4.10581 12.0539 4.09746 12.0813 4.09473 12.1095C4.09201 12.1378 4.09496 12.1663 4.10342 12.1934C4.11188 12.2205 4.12567 12.2456 4.14399 12.2673L5.10113 13.4079C5.17558 13.4956 5.30852 13.5089 5.39625 13.4371C8.28631 11.0947 11.9633 9.69359 15.9701 9.69359C20.006 9.69359 23.707 11.116 26.605 13.4876C26.6954 13.5621 26.8284 13.5488 26.9028 13.4584L27.86 12.3178C27.9371 12.2274 27.9238 12.0918 27.8307 12.0173ZM14.2951 23.6254C14.2951 24.0767 14.4743 24.5095 14.7935 24.8286C15.1126 25.1477 15.5454 25.327 15.9967 25.327C16.448 25.327 16.8808 25.1477 17.1999 24.8286C17.519 24.5095 17.6983 24.0767 17.6983 23.6254C17.6983 23.1741 17.519 22.7413 17.1999 22.4222C16.8808 22.1031 16.448 21.9238 15.9967 21.9238C15.5454 21.9238 15.1126 22.1031 14.7935 22.4222C14.4743 22.7413 14.2951 23.1741 14.2951 23.6254Z"
      fill="black"
    />
  </svg>
);

export default Wifi;
