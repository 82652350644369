import React from 'react';
import PropTypes from 'prop-types';
import { CDNURL } from '../../utils/general';

const SimpleSpinner = ({ className }) => (
  <img className={`${className} w-9`} alt="Loading..." src={`${CDNURL}spinner.gif`} />
);

SimpleSpinner.propTypes = {
  className: PropTypes.string,
};
SimpleSpinner.defaultProps = {
  className: '',
};

export default SimpleSpinner;
