import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MemoryRouter, Switch, Route, BrowserRouter } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import Home from './Home/Home';
import VenueDetail from './VenueDetail/VenueDetail';
import VenueList from './VenueList/VenueList';
import FixtureVenueList from './FixtureVenueList/FixtureVenueList';
import GA4Tracking from '../components/GA4Tracking/GA4Tracking';
import { request } from '../utils/general';
import SimpleSpinner from '../components/SimpleSpinner/SimpleSpinner';

const Root = ({ apiKey, isJSWidget, jsInitialRoute }) => {
  const [theme, setTheme] = useState(null);
  useEffect(() => {
    if (apiKey) {
      request('theme', { apiKey }).then(r => setTheme(r?.data));
    }
  }, [apiKey]);

  if (!apiKey) {
    return (
      <p>
        No API key found, please consult the documentation for instructions of how to pass your API
        key
      </p>
    );
  }

  if (!theme) {
    return <SimpleSpinner />;
  }

  const Router = isJSWidget ? MemoryRouter : BrowserRouter;

  return (
    <Router initialEntries={jsInitialRoute ? [jsInitialRoute] : undefined}>
      <GA4Tracking apiKey={apiKey} />
      <Layout isJsWidget={Boolean(isJSWidget)} theme={theme}>
        <Switch>
          <Route path="/venue/:venueId">
            <VenueDetail apiKey={apiKey} theme={theme} />
          </Route>
          <Route path="/venues/:fixtureId">
            <FixtureVenueList apiKey={apiKey} theme={theme} />
          </Route>
          <Route path="/venues">
            <VenueList apiKey={apiKey} theme={theme} />
          </Route>
          <Route path="/">
            <Home apiKey={apiKey} theme={theme} />
          </Route>
        </Switch>
      </Layout>
    </Router>
  );
};

Root.propTypes = {
  apiKey: PropTypes.string,
  isJSWidget: PropTypes.bool.isRequired,
  jsInitialRoute: PropTypes.string,
};

Root.defaultProps = {
  apiKey: null,
  jsInitialRoute: null,
};

export default Root;
