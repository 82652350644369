/* eslint-disable react/jsx-props-no-spreading */
/* global google */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  APIProvider,
  Map,
  Marker,
  useApiIsLoaded,
  useMap,
  useMapsLibrary,
} from '@vis.gl/react-google-maps';
import { CDNURL, mapsAPIKey } from '../../utils/general';

const MapWrapper = props => (
  <APIProvider apiKey={mapsAPIKey}>
    <VenuesMap {...props} />
  </APIProvider>
);

const VenuesMap = ({ venues, onPressMarker, userLocation, selectedVenueId }) => {
  const { t } = useTranslation();
  const map = useMap();
  const mapLibrary = useMapsLibrary('maps');
  const apiIsLoaded = useApiIsLoaded();

  const getLocation = ven => ({
    lat: ven?.location?.coordinate?.latitude,
    lng: ven?.location?.coordinate?.longitude,
  });

  useEffect(() => {
    if (venues?.length > 0 && apiIsLoaded && map) {
      const bounds = new google.maps.LatLngBounds();

      const getLatLng = ({ lat, lng }) => new google.maps.LatLng(lat, lng);
      if (venues.length > 1) {
        venues.forEach(ven => {
          const position = getLatLng(getLocation(ven));
          bounds.extend(position);
        });
        map.fitBounds(bounds);
      }
    }
  }, [map, mapLibrary, venues, apiIsLoaded]);

  const getIcon = isSelected => ({
    url: isSelected
      ? `${CDNURL}venus/mapMarkers/selected.png`
      : `${CDNURL}venus/mapMarkers/mapmarker.png`,
    scaledSize: new google.maps.Size(25, 30),
  });

  if (!apiIsLoaded) {
    return null;
  }

  return (
    <Map
      fullscreenControl={false}
      mapTypeControl={false}
      streetViewControl={false}
      className="h-full w-full overflow-hidden lg:rounded-xl"
      defaultCenter={
        venues?.[0]
          ? getLocation(venues[0])
          : {
              lat: 52.52,
              lng: 13.405,
            }
      }
      defaultZoom={15}
    >
      {venues.map(venue => (
        <Marker
          key={venue.id}
          onClick={() => onPressMarker(venue)}
          position={getLocation(venue)}
          icon={getIcon(venue?.id === selectedVenueId)}
          zIndex={venue?.id === selectedVenueId ? 99 : 1}
        />
      ))}
      {userLocation && (
        <Marker
          name={t('map_userLocation')}
          position={{ lat: userLocation.split(',')[0], lng: userLocation.split(',')[1] }}
          icon={{
            url: `${CDNURL}venus/mapMarkers/userMarker.png`,
            scaledSize: new google.maps.Size(25, 25),
          }}
        />
      )}
    </Map>
  );
};

export default MapWrapper;

VenuesMap.propTypes = {
  venues: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onPressMarker: PropTypes.func,
  userLocation: PropTypes.string,
  selectedVenueId: PropTypes.number,
};
VenuesMap.defaultProps = {
  onPressMarker: () => {},
  selectedVenueId: null,
  userLocation: null,
};
