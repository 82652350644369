import React from 'react';
import PropTypes from 'prop-types';
import styles from './Header.module.scss';

const Header = ({ children, backgroundImage }) => (
  <div className={styles.header} style={{ backgroundImage: `url(${backgroundImage})` }}>
    <div className={styles.header_inner}>{children}</div>
  </div>
);

Header.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundImage: PropTypes.string,
};

Header.defaultProps = {
  backgroundImage: '',
};

export default Header;
