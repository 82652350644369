import React from 'react';
import PropTypes from 'prop-types';

const Mobile = ({ className }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" className={`fill-current ${className}`}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2883 24.9668C18.9626 23.455 20.4332 20.7322 21.2068 15.8478C21.9804 10.9634 21.3723 8.24064 20.1769 6.72875C19.1774 5.4646 17.6449 4.89498 15.6915 4.75149L14.8122 10.303C14.918 10.3037 15.0302 10.3043 15.1483 10.3048C16.0114 10.3088 17.1865 10.3088 18.4361 10.2929L19.1483 10.2838L19.3427 10.9223C19.5126 11.4804 19.5339 12.2858 19.4987 13.0883C19.4618 13.9275 19.3555 14.8928 19.2042 15.8478C19.053 16.8028 18.8535 17.7681 18.6245 18.6073C18.4056 19.4098 18.1292 20.2151 17.7824 20.7733L17.3858 21.4118L16.6765 21.4027C15.4319 21.3868 14.2569 21.3868 13.3924 21.3907C13.2742 21.3913 13.1618 21.3919 13.0558 21.3926L12.1766 26.9441C14.1754 26.8006 15.8883 26.231 17.2883 24.9668ZM12.2886 19.248L11.2112 19.2635L9.64746 29.1367H10.7384C13.4853 29.1367 16.3034 28.5565 18.6467 26.4406C20.9653 24.3469 22.5783 20.964 23.3886 15.8478C24.1989 10.7316 23.6575 7.34868 22.0021 5.255C20.3292 3.13908 17.6948 2.5589 14.9479 2.5589L13.857 2.5589L12.2932 12.4321L13.3657 12.4476L13.4678 12.449C13.534 12.4498 13.6311 12.4509 13.7553 12.4522C14.0037 12.4547 14.3608 12.4578 14.7967 12.4598C15.4756 12.4629 16.3469 12.4636 17.2973 12.4561C17.3077 12.6939 17.3075 12.9858 17.2923 13.3304C17.2597 14.0726 17.1638 14.955 17.0224 15.8478C16.881 16.7406 16.6974 17.623 16.4949 18.3652C16.4009 18.7098 16.3086 19.0017 16.223 19.2395C15.2749 19.232 14.4034 19.2327 13.7235 19.2358C13.2869 19.2378 12.9289 19.2409 12.6797 19.2434C12.5551 19.2446 12.4576 19.2458 12.3912 19.2466L12.2886 19.248Z"
    />
  </svg>
);

Mobile.propTypes = {
  className: PropTypes.string,
};

Mobile.defaultProps = {
  className: 'h-6 w-6 md:h-8 md:w-8',
};

export default Mobile;
