import React from 'react';

const OutdoorScreen = () => (
  <svg width={32} height={32} viewBox="0 0 40 30">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-470.000000, -4364.000000)">
        <g transform="translate(120.000000, 3842.000000)">
          <g transform="translate(346.000000, 512.000000)">
            <rect x="0" y="0" width="48" height="48" />
            <path
              d="M42,10 C43.1045695,10 44,10.8954305 44,12 L44,34 C44,35.1045695 43.1045695,36 42,36 L6,36 C4.8954305,36 4,35.1045695 4,34 L4,12 C4,10.8954305 4.8954305,10 6,10 L42,10 Z M22.064,18.088 L14.07,18.088 L14.07,20.132 L16.87,20.132 L16.87,28 L19.264,28 L19.264,20.132 L22.064,20.132 L22.064,18.088 Z M26.412,18.088 L23.724,18.088 L27.476,28 L29.87,28 L33.692,18.088 L31.046,18.088 L28.764,25.116 L28.708,25.116 L26.412,18.088 Z"
              fill="#000000"
            />
            <rect fill="#000000" opacity="0.3" x="6" y="38" width="36" height="2" rx="1" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default OutdoorScreen;
