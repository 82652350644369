import React from 'react';
import { useTranslation } from 'react-i18next';
import { openingTimesProps } from '../../../utils/customPropTypes';
import { useLocale } from '../../../utils/date';

const OpeningTimes = ({ openingTimes }) => {
  const { t } = useTranslation();
  const locale = useLocale();

  const openingHoursArray = Object.values(openingTimes || {});
  const weekdays = [1, 2, 3, 4, 5, 6, 0].map(i => locale.localize.day(i));

  return weekdays.map((day, index) => {
    const { open, close } = openingHoursArray[index];
    return (
      <div className="mb-3 flex justify-between lg:justify-start" key={day}>
        <span className="font-bold capitalize text-darkGrey">{day}</span>
        <span className="uppercase text-grey lg:ml-4 lg:pr-12">
          {`${open || t('general_closed')} - ${close || t('general_closed')}`}
        </span>
      </div>
    );
  });
};

OpeningTimes.propTypes = {
  openingTimes: openingTimesProps,
};

OpeningTimes.defaultProps = {
  openingTimes: {},
};

export default OpeningTimes;
