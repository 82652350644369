import React from 'react';
import { useTranslation } from 'react-i18next';
import { facilitiesProps } from '../../../utils/customPropTypes';
import Screen from './Icons/Screen';
import ServesFood from './Icons/ServesFood';
import Wifi from './Icons/Wifi';
import Projector from './Icons/Projector';
import Outdoor from './Icons/Outdoor';
import AllowPet from './Icons/AllowPet';
import Sound from './Icons/Sound';
import OutdoorScreen from './Icons/OutdoorScreen';

const classes = 'flex flex-col items-center w-20 lg:mb-4';

const VenueFeatures = ({ facilities }) => {
  const { t } = useTranslation();

  const componentMap = {
    numberOfScreens: {
      icon: <Screen key="screens" />,
      text: `${facilities.numberOfScreens} ${t('venue_featScreens', {
        count: facilities.numberOfScreens,
      })}`,
    },
    hasSound: {
      icon: <Sound key="sound" />,
      text: t('venue_featCommentary'),
    },
    hasBigScreen: {
      icon: <Projector key="projector" />,
      text: t('venue_featProjector'),
    },
    hasWifi: {
      icon: <Wifi key="wifi" />,
      text: t('venue_featWifi'),
    },
    servesFood: {
      icon: <ServesFood key="food" />,
      text: t('venue_featFood'),
    },
    hasGarden: {
      icon: <Outdoor key="outdoor" />,
      text: t('venue_featGarden'),
    },
    allowsPet: {
      icon: <AllowPet key="allowPet" />,
      text: t('venue_featPet'),
    },
    hasOutdoorScreen: {
      icon: <OutdoorScreen key="outdoorScreen" />,
      text: t('venue_featOutdoorScreen'),
    },
  };

  return (
    <div className="flex flex-wrap gap-4">
      {Object.entries(facilities)
        .filter(key => key[1] && componentMap[key[0]])
        .map(key => (
          <div key={componentMap[key[0]].text} className={classes}>
            {componentMap[key[0]].icon}
            <p className="pt-5 text-center text-xs text-darkGrey">{componentMap[key[0]].text}</p>
          </div>
        ))}
    </div>
  );
};

VenueFeatures.propTypes = {
  facilities: facilitiesProps,
};

VenueFeatures.defaultProps = {
  facilities: {},
};

export default VenueFeatures;
