import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getNavigationParams, getURLQuery } from '../../../utils/general';
import { venueProps } from '../../../utils/customPropTypes';
import Globe from '../../Icons/Globe';
import Mobile from '../../Icons/Mobile';
import Directions from '../../Icons/Directions';
import { eventTypes, trackEvent } from '../../../utils/tracking';

const ContactLinks = ({ venue, apiKey }) => {
  const { t } = useTranslation();
  const { location, name, website, phoneNumber, id } = venue || {};
  const navigationParams = getURLQuery(
    getNavigationParams(location?.address1, location?.coordinate, name, location?.googlePlaceId),
  );

  const linkButtons = [
    {
      icon: <Globe />,
      text: 'venue_website',
      href: website?.startsWith('http') ? website : `https://${website}`,
      openInNewTab: true,
      show: Boolean(website),
      tag: eventTypes.website,
    },
    {
      icon: <Mobile />,
      text: 'venue_phone',
      href: phoneNumber ? `tel:${phoneNumber}` : null,
      show: Boolean(phoneNumber),
      tag: eventTypes.phone,
    },
    {
      icon: <Directions />,
      text: 'venue_directions',
      href: `https://www.google.com/maps/dir/${navigationParams}`,
      openInNewTab: true,
      show: true,
      tag: eventTypes.directions,
    },
  ];

  return (
    linkButtons.filter(e => e.href).length > 0 && (
      <div className="flex gap-2">
        {linkButtons.map(({ href, text, icon, openInNewTab, show, tag }) =>
          show ? (
            <a
              aria-label={text}
              key={text}
              className="flex items-center rounded-lg bg-veryLightGrey px-4 py-2 hover:opacity-75"
              href={href}
              target={openInNewTab && '_blank'}
              rel={openInNewTab && 'noreferrer noopener'}
              onClick={() => trackEvent(tag, apiKey, { venueID: id })}
            >
              {icon}
              <p className="ml-1 text-xs font-semibold">{t(text)}</p>
            </a>
          ) : null,
        )}
      </div>
    )
  );
};

ContactLinks.propTypes = {
  venue: venueProps,
  apiKey: PropTypes.string.isRequired,
};

ContactLinks.defaultProps = {
  venue: {},
};

export default ContactLinks;
