import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const HeaderButton = ({ onClick, icon, text }) => (
  <button type="button" className={styles.header_btn} onClick={onClick}>
    {icon}
    <span className="hiddenA mx-3 text-sm leading-none md:block">{text}</span>
  </button>
);

HeaderButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
};

export default HeaderButton;
