import React from 'react';
import PropTypes from 'prop-types';

const Globe = ({ className }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" className={`fill-current ${className}`}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.47533 15.8657C5.51208 9.31987 11.7251 4.01343 18.3525 4.01343C24.98 4.01343 29.5121 9.31987 28.4753 15.8657C27.4386 22.4115 21.2255 27.7179 14.5981 27.7179C7.9707 27.7179 3.43857 22.4115 4.47533 15.8657ZM18.0112 6.16838C12.5888 6.16838 7.5054 10.51 6.65714 15.8657C5.80889 21.2214 9.51699 25.563 14.9394 25.563C20.3619 25.563 25.4453 21.2214 26.2935 15.8657C27.1418 10.51 23.4337 6.16838 18.0112 6.16838Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2045 8.5239C12.7855 10.3767 11.6608 12.9711 11.2023 15.8656C10.7439 18.7602 11.0468 21.3546 11.8789 23.2074C12.7158 25.0709 14.0405 26.1017 15.5811 26.1017L15.4105 27.1792C13.3361 27.1792 11.7701 25.7979 10.8557 23.7617C9.93642 21.7149 9.62738 18.9219 10.1114 15.8656C10.5955 12.8094 11.7893 10.0164 13.3569 7.96954C14.9163 5.93338 16.9199 4.55212 18.9942 4.55212L18.8236 5.6296C17.283 5.6296 15.6318 6.66037 14.2045 8.5239Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.0715 8.5239C21.9036 10.3767 22.2065 12.9711 21.7481 15.8656C21.2896 18.7602 20.1649 21.3546 18.7459 23.2074C17.3186 25.0709 15.6674 26.1017 14.1268 26.1017L13.9562 27.1792C16.0305 27.1792 18.0341 25.7979 19.5935 23.7617C21.1611 21.7149 22.3549 18.9219 22.839 15.8656C23.323 12.8094 23.014 10.0164 22.0947 7.96954C21.1803 5.93338 19.6143 4.55212 17.54 4.55212L17.3693 5.6296C18.9099 5.6296 20.2346 6.66037 21.0715 8.5239Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.4798 10.4033C21.9635 10.7787 19.8833 11.0171 17.6063 11.0171C15.3293 11.0171 13.3246 10.7787 11.9272 10.4033C11.2246 10.2146 10.7051 9.99893 10.382 9.78112C10.0316 9.54487 10.044 9.40153 10.0441 9.40085L8.95319 9.40085C8.85904 9.99524 9.2497 10.4188 9.66408 10.6982C10.1058 10.996 10.7376 11.2447 11.4867 11.4459C12.9928 11.8505 15.0935 12.0945 17.4356 12.0945C19.7778 12.0945 21.9558 11.8505 23.59 11.4459C24.4029 11.2447 25.1135 10.996 25.6495 10.6982C26.1524 10.4188 26.6772 9.99524 26.7714 9.40085L25.6805 9.40085C25.6803 9.40153 25.6473 9.54487 25.2221 9.78112C24.83 9.99893 24.2422 10.2146 23.4798 10.4033Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.7507 21.328C20.3533 20.9527 18.3486 20.7143 16.0716 20.7143C13.7947 20.7143 11.7144 20.9527 10.1981 21.328C9.43572 21.5168 8.84788 21.7325 8.45582 21.9503C8.03058 22.1865 7.99756 22.3299 7.99745 22.3305L6.90655 22.3305C7.00069 21.7361 7.52552 21.3126 8.0284 21.0332C8.56446 20.7354 9.27499 20.4867 10.0879 20.2855C11.7221 19.8809 13.9001 19.6368 16.2423 19.6368C18.5844 19.6368 20.6851 19.8809 22.1912 20.2855C22.9404 20.4867 23.5721 20.7354 24.0138 21.0332C24.4282 21.3126 24.8189 21.7361 24.7247 22.3305L23.6338 22.3305C23.6339 22.3299 23.6463 22.1865 23.2959 21.9503C22.9728 21.7325 22.4533 21.5168 21.7507 21.328Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7276 5.09088L15.3145 26.6404H14.2236L17.6367 5.09088H18.7276Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.6521 15.3269L27.4703 15.3269L27.2996 16.4044L5.48145 16.4044L5.6521 15.3269Z"
    />
  </svg>
);

Globe.propTypes = {
  className: PropTypes.string,
};

Globe.defaultProps = {
  className: 'h-6 w-6 md:h-8 md:w-8',
};

export default Globe;
