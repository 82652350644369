import { format, isToday, isTomorrow, isYesterday, isValid } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { enGB, fr, de } from 'date-fns/locale';
import i18next from 'i18next';

const getLocaleDate = (date, locale, formatStr = 'EEE d') =>
  isValid(new Date(date))
    ? format(new Date(date), formatStr, {
        locale,
      })
    : date;

const getRelativeLocaleDate = (date, locale, t, formatStr) => {
  if (!Date.parse(date)) {
    return date;
  }

  if (isToday(date)) {
    return t('general_today');
  }
  if (isTomorrow(date)) {
    return t('general_tomorrow');
  }
  if (isYesterday(date)) {
    return t('general_yesterday');
  }
  return getLocaleDate(date, locale, formatStr);
};

export const useLocale = () => {
  switch (i18next.resolvedLanguage) {
    case 'fr-FR':
    case 'fr':
      return fr;
    case 'de':
      return de;
    default:
      return enGB;
  }
};

export const useLocaleDate = () => {
  const locale = useLocale();
  return (date, formatStr) => getLocaleDate(date, locale, formatStr);
};

export const useRelativeLocaleDate = () => {
  const locale = useLocale();
  const { t } = useTranslation();
  return (date, formatStr) => getRelativeLocaleDate(date, locale, t, formatStr);
};
