import React from 'react';
import PropTypes from 'prop-types';

const Chevron = ({ size, className, innerClass }) => (
  <svg className={className} width={size} height={size} viewBox="0 0 11 7">
    <g stroke="none" strokeWidth="1" fillRule="evenodd">
      <g
        transform="translate(-324.000000, -429.000000)"
        className={`fill-current ${innerClass} stroke-current`}
        fillRule="nonzero"
      >
        <g transform="translate(15.000000, 403.000000)">
          <g>
            <g transform="translate(315.000000, 30.000000) scale(-1, 1) rotate(-180.000000) translate(-315.000000, -30.000000) translate(300.000000, 15.000000)">
              <g>
                <g transform="translate(-0.000000, 0.000000)">
                  <g transform="translate(10.000000, 13.000000)">
                    <path d="M9.49692816,0.249953918 C9.49692816,0.316270152 9.47064009,0.37988073 9.42367801,0.426703918 L4.92367801,4.92670392 C4.82605304,5.0242994 4.66780297,5.0242994 4.57017801,4.92670392 L0.0701780052,0.426703918 C-0.0245650472,0.328609196 -0.0232100883,0.172682724 0.0732233613,0.0762492743 C0.169656811,-0.0201841753 0.325583283,-0.0215391342 0.423678005,0.0732039182 L4.74692801,4.39645392 L9.07017801,0.0732039182 C9.14167657,0.00172693929 9.24918716,-0.0196515583 9.34259164,0.0190344237 C9.43599613,0.0577204056 9.49692816,0.148854944 9.49692816,0.249953918 Z" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Chevron.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
  innerClass: PropTypes.string,
};

Chevron.defaultProps = {
  size: 8,
  className: '',
  innerClass: '',
};

export default Chevron;
